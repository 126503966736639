
import Carousel from "@/components/common/Carousel.vue";
import Plan from "@/components/pricingTable/Plan.vue";
import PricingTableDisclaimers from "@/components/pricingTable/PricingTableDisclaimers.vue";
import ModalBuy from "@/components/views/subscriptions/modals/buyProcess/ModalBuy.vue";
import ModalGetAQuote from "@/components/views/subscriptions/modals/ModalGetAQuote.vue";
import { CarouselProps } from "@/types/carousel";
import { FlatSubscription, PlanContext, PlanModel } from "@/types/localtypes";
import { GetAQuoteRequest, PackageCore, PaymentType, SubscriptionTypes } from "@jfrog-ba/myjfrog-common";
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import PromoPT15USDHeader from "@/components/landingPages/quickPurchase/promotions/proteam15usd/PromoPT15USDHeader.vue";
import { Getter } from "vuex-class";
import LightUpgradeSuccessAfterExpiration from "@/components/views/subscriptions/lightUpgrade/LightUpgradeSuccessAfterExpiration.vue";
import LightUpgradeSuccessRegular from "@/components/views/subscriptions/lightUpgrade/LightUpgradeSuccessRegular.vue";
import { cookiesService } from "@/services/cookies";

@Component({
  name: "BuyCarousel",
  components: { Carousel, PricingTableDisclaimers, PromoPT15USDHeader },
})
export default class BuyCarousel extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Getter("lightUiEnabled", { namespace: "application" })
  lightUiEnabled!: boolean;
  @Prop({ default: null })
  private subscription!: FlatSubscription;
  @Prop({ default: false })
  private tiersPricesLoaded!: boolean;
  @Prop({ default: undefined })
  private context!: PlanContext;

  subscriptionTypes: SubscriptionTypes[] = [];
  allPlans: PlanModel[] = [];

  lightUpgradeState = {
    inSuccess: false,
    buyFromExpiredTrial: false,
  };

  get displayLightUpgradeSuccessCard() {
    return this.lightUpgradeState.inSuccess;
  }

  get lightUpgradeSuccessCardComponent() {
    return this.lightUpgradeState.buyFromExpiredTrial ? LightUpgradeSuccessAfterExpiration : LightUpgradeSuccessRegular;
  }
  async created() {
    try {
      this.$emit("loading", true);
      this.subscriptionTypes = await this.$jfSubscriptions.getSubscriptionTypes(this.subscription.accountNumber);
      this.$emit("loading", false);
      this.createPlans();
      this.listenToBusEvent();
    } catch (e) {
      this.$log.error(e);
      this.$jfNotification.error({ text: this.$jfMessages.subscriptions_list_fetch_failed });
      this.$emit("loading", false);
    }
  }

  private createPlans() {
    const jcr =
      this.subscriptionTypes && this.subscriptionTypes.find(value => value.eventSubscriptionType === "CLOUD_JCR");
    const proTeam =
      this.subscriptionTypes && this.subscriptionTypes.find(value => value.paymentType === "PRO_TEAM_MONTHLY");
    const entTeam =
      this.subscriptionTypes && this.subscriptionTypes.find(value => value.paymentType === "ENTERPRISE_MONTHLY");
    const entXTeam =
      this.subscriptionTypes && this.subscriptionTypes.find(value => value.paymentType === "ENTERPRISE_X_MONTHLY");
    if (!proTeam || !jcr || !entTeam || !entXTeam) {
      this.$log.error("Some paymentTypes are not found");
      this.notifyError(this.$jfMessages.subscriptions_buy_error_unknown);
      return;
    }

    if (!this.isEnterprise) {
      this.allPlans.push(
        this.$jfPricing.createPlan(
          {
            currentPlan: false,
            canPurchasePipelines: this.canPurchasePipelines,
            buttonText: "Buy Now",
            isProcessable: true,
            core: "DEVOPS",
          },
          proTeam,
        ),
      );
    }

    this.allPlans.push(
      this.$jfPricing.createPlan(
        {
          subscriptionType: "ENTERPRISE_MONTHLY",
          currentPlan: false,
          canPurchasePipelines: this.canPurchasePipelines,
          buttonText: "Buy Now",
          isProcessable: this.actionIsProcessable("ENTERPRISE_MONTHLY"),
          groupXrayPremium: true,
          shouldMentionOnlyCurrentPlan: this.isEnterprise,
          core: "DEVOPS",
          forceAdditionalType: true,
        },
        entTeam,
        [entXTeam],
      ),
      this.$jfPricing.createPlan({
        subscriptionType: "CLOUD_ENTERPRISE_PLUS",
        canPurchasePipelines: this.canPurchasePipelines,
        buttonText: "Contact Us",
        isProcessable: this.actionIsProcessable("ENTERPRISE_PLUS"),
        groupXrayPremium: true,
        core: "DEVOPS",
        rateText: "Full Devops Platform",
      }),
      this.$jfPricing.createPlan({
        subscriptionType: "ENT",
        currentPlan: false,
        canPurchasePipelines: true,
        buttonText: "Contact Us",
        actionBtnDisabled: false,
        isProcessable: true,
        core: "DEVSECOPS",
      }),
      this.$jfPricing.createPlan({
        subscriptionType: "CLOUD_ENTERPRISE_PLUS",
        currentPlan: false,
        canPurchasePipelines: true,
        buttonText: "Contact Us",
        actionBtnDisabled: false,
        isProcessable: true,
        core: "DEVSECOPS",
        rateText: "Full Devops Platform",
      }),
    );
  }

  get isEnterprise() {
    return this.subscription.meta.isEnterprise || this.subscription.meta.isEnterpriseX;
  }

  get plans() {
    return this.$jfPricing.fillTooltipNumbers(this.allPlans);
  }

  notifyError(errorMessage: string) {
    this.globalBus.$emit("notifyError", errorMessage);
  }

  handleOpenCalculator(calculatorPaymentType: PaymentType) {
    this.$emit("openCalculator", calculatorPaymentType);
  }

  get canPurchasePipelines() {
    const { isPipelinesAvailableInRegion, isJfrogUnified } = this.subscription.meta;
    return !!isPipelinesAvailableInRegion && !!isJfrogUnified;
  }

  get carouselData(): CarouselProps {
    return {
      context: this.context,
      components: this.plans.map((plan, index) => ({
        component: Plan,
        componentProperties: {
          subscriptionTypes: this.subscriptionTypes,
          class: "isDisabled",
          onPlanSelected: this.onSelectPlan,
          isCurrent: plan.currentPlan,
          shadowType: "dark-shadow",
          ...plan,
          id: index,
          context: this.context,
          core: plan.core,
        },
      })),
      initialState: {
        showAll: true,
        initialIndex: 0,
      },
    };
  }

  showUnprocessableExplanationModal(text: string) {
    this.$jfModal.confirm({
      body: text,
      buttonsText: { confirm: "Close" },
      size: "md",
      onConfirm: () => true,
      displayCancelButton: false,
    });
  }

  actionIsProcessable(paymentType: PaymentType) {
    if (paymentType === "MONTHLY" || paymentType === "JCR") {
      // buy pro
      return true;
    }
    if (paymentType === "DEDICATED") {
      // cloud enterprise > should open get a quote flow
      return false;
    }
    // buy proX : need to check conditions before allowing the buy
    return !this.buyNotProcessableExplanation;
  }

  get buyNotProcessableExplanation() {
    return this.$jfSubscriptions.getBuyNotProcessableExplanation(this.subscription.meta);
  }

  private onSelectPlan(selectedPlanTitle: string, selectedPlanCore: PackageCore) {
    const selectedPlan: PlanModel = this.allPlans.find(
      plan => plan.title === selectedPlanTitle && plan.core === selectedPlanCore,
    ) as PlanModel;
    if (selectedPlan.isEnterprisePlusPlan || selectedPlan.core === "DEVSECOPS") {
      return this.displayGetAQuoteModal(selectedPlan);
    } else {
      const buyNotProcessableExplanation = this.buyNotProcessableExplanation;
      if (!this.actionIsProcessable(selectedPlan.paymentType) && buyNotProcessableExplanation) {
        this.$jfSubscriptions.sendSubscriptionActionRequest({
          reason: buyNotProcessableExplanation.reason,
          actionType: "buy",
          serverName: this.serverName,
          marketoCookie: this.$jfMarketo.getCookie(),
        });
        return this.showUnprocessableExplanationModal(buyNotProcessableExplanation.message);
      }
    }

    return this.showBuyModal(selectedPlan);
  }

  get serverName() {
    return this.subscription && this.subscription.serverName;
  }

  displayGetAQuoteModal(selectedPlan: PlanModel) {
    const actionType: GetAQuoteRequest["actionType"] = "buy";
    this.$jfModal.showCustom(
      ModalGetAQuote,
      "lg",
      {
        actionType: actionType,
        serverName: this.serverName,
        isEnterprisePlus: selectedPlan.isEnterprisePlusPlan,
        title: `${selectedPlan.title}${selectedPlan.core === "DEVSECOPS" ? " with Advanced Security Pack" : ""}`,
        core: selectedPlan.core,
      },
      {
        title: selectedPlan.title + " Subscription",
        headerBorder: false,
        footerBorder: false,
        clickShouldClose: false,
        shakeIfCantClose: false,
      },
    );
  }

  showBuyModal(selectedPlan: PlanModel) {
    this.$jfModal.showCustom(
      ModalBuy,
      "md",
      {
        subscription: this.subscription,
        selectedPlan: selectedPlan,
        subscriptionTypes: this.subscriptionTypes,
      },
      {
        headerBorder: false,
        footerBorder: false,
        clickShouldClose: false,
        shakeIfCantClose: false,
      },
    );
  }

  onLightUpgradeSuccess(config: { buyFromExpiredTrial: boolean }) {
    this.lightUpgradeState.inSuccess = true;
    this.lightUpgradeState.buyFromExpiredTrial = config.buyFromExpiredTrial;
    cookiesService.setLightUpgradeInSuccess("" + this.subscription.accountNumber);
  }

  busEvent: { [key: string]: (...params: any[]) => any } = {
    lightUpgradeSuccess: this.onLightUpgradeSuccess,
  };

  listenToBusEvent() {
    for (let key in this.busEvent) {
      this.globalBus.$on(key, this.busEvent[key]);
    }
  }

  beforeDestroy() {
    for (let key in this.busEvent) {
      this.globalBus.$off(key, this.busEvent[key]);
    }
  }
}
