
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { LoadingMaskProps } from "@/types/loadingMask";
import { PlanContext, FlatSubscription } from "@/types/localtypes";
import { ViewContext } from "@/types/services/app";
import { MenuConfiguration } from "@/types/services/menus";
import { SubscriptionDTO } from "@jfrog-ba/myjfrog-common";
import LoadingMask from "@/components/common/LoadingMask.vue";
import PageSection from "@/components/layout/PageSection.vue";
import PageView from "@/components/layout/PageView.vue";
import BuyCarousel from "@/components/views/subscriptions/buy/BuyCarousel.vue";

@Component({
  name: "SubscriptionsBuy",
  components: {
    PageView,
    PageSection,
    LoadingMask,
    BuyCarousel,
  },
})
export default class SubscriptionsBuy extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Action("setMenuConfiguration", { namespace: "application" })
  setMenuConfiguration!: (menuConfiguration: MenuConfiguration) => void;
  @Action("setViewContext", { namespace: "application" })
  setViewContext!: (viewContext: ViewContext) => void;
  @Getter("subscriptions", { namespace: "subscriptions" })
  subscriptions!: SubscriptionDTO[];
  accountNumber!: FlatSubscription["accountNumber"];
  subscription: FlatSubscription | null = null;
  isLoadingData: boolean = false;
  planContext: PlanContext = "buy";

  @Watch("subscription")
  async onSubscriptionChanged() {
    this.setMenuConfiguration(await this.defineMenuConfiguration());
    this.setViewContext(this.defineViewContext());
  }

  defineViewContext(): ViewContext {
    return this.subscriptions && this.subscription
      ? { subscriptionMetas: this.subscriptions.map(s => s.meta), currentSubscriptionMeta: this.subscription.meta }
      : {};
  }
  get isMobile() {
    return this.$mq === "mobile";
  }

  get sectionClasses() {
    const classes = ["px-0", "pb-0"];
    if (this.isMobile) {
      classes.push("scrollable");
    } else {
      classes.push("noscroll");
    }
    return classes;
  }

  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading || this.isLoadingData,
    };
  }

  setLoading(loading: boolean) {
    this.isLoadingData = loading;
  }

  get isLoading() {
    return !this.subscription;
  }

  get serverName() {
    return (this.subscription && this.subscription.serverName) || "";
  }

  async defineMenuConfiguration(): Promise<MenuConfiguration> {
    this.globalBus.$emit("onMenuConfigurationChange");
    if (this.subscription) {
      const meta = this.subscription.meta;
      return await this.$jfMenus.defineContextualMenuConfiguration(
        this.serverName,
        meta,
        (menuId, serverName, tooltipContent, tooltipHasSubmitAction) => {
          this.globalBus.$emit(
            "onMenuTooltipClick",
            menuId,
            serverName,
            tooltipContent,
            tooltipHasSubmitAction,
            this.$jfSubscriptions.getSubscriptionRequestReason(menuId, meta),
          );
        },
      );
    }
    return {};
  }

  notFound() {
    this.$router.replace({ path: "/404" });
  }
  notAllowed() {
    this.$router.replace({ path: "/" });
  }

  async fetchSubscription() {
    try {
      const subscription = await this.$jfSubscriptions.getSubscription(this.accountNumber);
      const meta = subscription.meta;
      if (!this.$jfSubscriptions.isBuyPageReachable(meta)) {
        this.notAllowed();
      }
      this.subscription = this.$jfSubscriptions.transformSubscription(subscription);
    } catch (error) {
      if (error.httpStatusText === 500) {
        this.$jfNotification.error({ text: this.$jfMessages.app_something_went_wrong });
      } else {
        this.notFound();
      }
    }
  }

  mounted() {
    this.$jfModal.closeAll();
    const queryParam = parseInt(this.$route.params.accountNumber) as SubscriptionDTO["accountNumber"];
    if (Number.isNaN(queryParam)) {
      this.notFound();
    } else {
      this.accountNumber = queryParam;
      this.fetchSubscription();
    }
  }
}
